import React, { Children, createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ConfigProvider, DatePicker,Tree, Divider, FormInstance, Input, List, Pagination, Select, Table, TablePaginationConfig, Tabs, Tooltip, TreeSelect, Typography, Spin, AutoComplete } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { axAffixExSubjectDetails, axAffixExSubjectList, axAffixSubjectDetail, axSubjectAffixList, axSubjectList, getCompanyList, getProofInitBalance, getProofList, singleAffixDetailRemainder, singleSubjectRemainder } from '../../utils/api'
import { uniAxList, uniAxSearchList, uniCurrentTime, uniDeepArray, uniExportExcel, uniFilterItem, uniFormProcess, uniModifyAxList, uniSelectRows, uniToProof, uniValueToName } from '../../utils/uniFucs'
import { borrowOrLend, leadBorrow, monthRages } from '../../utils/constant';
import tablecss from '../../css/custable.module.less'
import { TreeNode } from 'antd/lib/tree-select';
import { AxDataform, ColumnItem, FormItemObj } from '../../utils/interfaces';
import TopSearch from '../../components/TopSearch';
import SuperTable from '../../components/SuperTable';

export default function AffixDetail() {
const pathname = "/indexpage/affixdetail"
const searchRef = createRef<FormInstance>()
const initSearchParams = {myrange: [moment(),moment()]}
const initMainControl = {selectSubject:null,selectAffix:null,checkedDetail:null,detialAutoValue:null}
/* hooks */
let location = useLocation()
const {state} = useLocation()
const navigate = useNavigate()
const formModalRef = createRef<FormInstance>()
const [addModal, setAddModal] = useState(false)
const [chgModal, setChgModal] = useState(false)
const [logModal, setLogModal] = useState(false)
const [confirmModal, setConfirmModal] = useState(false)
const [staticModal, setStaticModal] = useState(false)
const [cancelModal, setCancelModal] = useState(false)
const [companyList, setCompanyList] = useState([])
const [logList, setLogList] = useState([])
const [rowList, setRowList] = useState([] as any)
const [backRowList, setBackRowList] = useState([])
const [modalItemVisible, setModalItemVisible] = useState({})
const [showCashAccount, setShowCashAccount] = useState(true)
const [selectedRowKeys, setSelectedRowKeys] = useState([])
const [selectedRows, setSelectedRows] = useState([])
const [currentRow, setCurrentRow] = useState({} as any)
let [searchParams, setSearchParams] = useState({beginYm:moment().format('YYYY-MM'),endYm:moment().format('YYYY-MM')} as any)
const [tempSearchParams, setTempSearchParams] = useState({beginYm:uniCurrentTime({day:false}),endYm:uniCurrentTime({day:false})} as any)
const [staffList, setStaffList] = useState([])
const [feeTypeList, setFeeTypeList] = useState([])
const [tableLoading, setTableLoading] = useState(false)
const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 11})
const [subjectList, setSubjectList] = useState([] as any)
const [businessList, setBusinessList] = useState([])
const [currentSubjectId, setCurrentSubjectId] = useState({id:1})
const [loadFlag, setLoadFlag] = useState(false)
const [modalLoading, setModalLoading] = useState(false)
const [summeryrowlist, setsummeryrolist] = useState([] as any)
const [affixSummeryrowlist, setaffixSummeryrolist] = useState([] as any)
const [affixInitRowList, setAffixInitRowList] = useState([] as any)
const [mainControl,setMainControl] = useState({selectSubject:null,selectAffix:null,checkedDetail:null,detialAutoValue:null} as any)
const [initRowlist, setInitRowlist] = useState([])
const [dataRowlist, setDataRowlist] = useState([])
const [affixList,setAffixList] = useState([] as any)
const [affixDetailList,setAffixDetailList] = useState([])
const [affixDetailAutoList,setAffixDetailAutoList] = useState([])
const [searchYmRange,setSearchYmRange] = useState([] as any)

/* funcs */
const searchConfirm = async (formValus:any) => {
  searchParams = {...await uniFormProcess(formValus,searchList)}
  setSearchParams(searchParams)
  uniAxList(dataRow())
  // pagination.current = 1
  // setPagination({...pagination,current:1});
  // // searchParams = {...initSearchParams,...formValus}
  // // setSelectedRows([])
  // // setSelectedRowKeys([])
  // searchParams = await uniFormProcess({...initSearchParams,...formValus},searchList)
  // setSearchParams(searchParams)
  // uniAxList(dataRow())
}
const routerFlash = () => {
  // uniAxList(dataRow())
  uniAxList(dataSubject)
  uniAxList(dataCompanyList)
}
//拖拽展开
const resizeFn = () => {
  let resize:any = document.querySelector('#listresize1')
  let element:any = document.querySelector('#listref1')
  let tablewrap:any = document.querySelector('#tablewrap1')
  // let listtab:any = document.querySelector('.listtab')
  resize.addEventListener('mousedown', function(e:any){
    let startX = e.pageX
    let width = element.offsetWidth
    // let listwidth = listtab.offsetWidth
    let tablewith = tablewrap.offsetWidth
    document.addEventListener('mousemove', move)
    let timer:any = null
    function move(e:any){
      let moveX:any // 左右这里切换计算顺序即可
      moveX = startX - e.pageX
      clearTimeout(timer)
      timer = setTimeout(() => {
        element.style.width = (width + moveX) + 'px'
        // listtab.style.width = (listwidth + moveX) + 'px'
        tablewrap.style.width = (tablewith - moveX) + 'px'
      }, 5);
    }
    document.addEventListener('mouseup',function(){
      document.removeEventListener('mousemove', move)
    }, {once: true})
  })
}

/* elements */
//搜索行
const searchList:FormItemObj[] = [
{label: '公司', prop:'companyId',type:'select',dataList:companyList,required:true,change:(value:any)=>{
  let finditem:any = companyList.find((item:any)=>item.id===value)
  if (finditem.initialize===1) setSearchYmRange([finditem.startYm,finditem.endYm])
  else setSearchYmRange([])
  let accountid = searchRef.current?.getFieldValue('accountingId')
  if (accountid) uniAxList(dataAffixDetails(accountid,value))
}},
{label: '月份', prop:'ym',type:'dateRange',required:true,daterangeProps:{type:'month',returnType:'string',rangeKeys:['beginYm','endYm'],shortCuts:{},disabledDate:(current)=>{
  if (searchYmRange.length<2) return true
  else return current<moment(searchYmRange[0]).startOf('month') || current>moment(searchYmRange[1]).endOf('month')
}}},
{label: '科目', prop:'subjectId',required:true,type:'select',dataList:subjectList,selectProps:{childrenKeys:['subjectNo','name']},change:(value:any)=>{
  searchRef.current?.setFieldsValue({accountingId:null,detailId:null})
  setAffixDetailList([])
  if (value) uniAxList(dataSubjectAffix(value))
  else setAffixList([])
}},
{label: '辅助核算', prop:'accountingId',type:'select',dataList:affixList,selectProps:{propId:'accountingId',propName:'accountingName'},change:(value:any)=>{
  searchRef.current?.setFieldsValue({detailId:null})
  let companyid = searchRef.current?.getFieldValue('companyId')
  if (value) uniAxList(dataAffixDetails(value,companyid))
  else setAffixDetailList([])
}},
{label: '核算详情', prop:'detailId',type:'select',dataList:affixDetailList}
]
//列项
const columns:ColumnItem[] = [
{title: '日期',key: 'billYmd',},
// {title: '凭证号',key: 'proofNo'},
{title: '凭证号',key: 'proofNo',ellipsis:false,render: (value:any,row:any) => {
  let temparr = value.split(',')
  return <div style={{maxWidth:170}}>
    {temparr.map((item:any) => <span key={item} ><Typography.Link onClick={()=>uniToProof(row,row.proofId,navigate)}>{item}</Typography.Link> </span>)}
  </div>
}},
{title: '辅助核算',key: 'detailNo',custval:({row,value}:any)=>row.accountingName+'_'+value+'_'+row.detailName},
{title: '借贷方向',key: 'borrowLend',custval:({row,value}:any)=>uniValueToName({list:borrowOrLend,value})},
{title: '金额',key: 'price',type:'price'},
{title: '摘要',key: 'summary'},
]

/* 接口参数 */
const dataRow = (current?:number):AxDataform => ({
  params: {...searchParams},
  axApi: axAffixExSubjectDetails,
  setList: setRowList,
  setTableLoading,
  setPagination
})
//获取科目关联的辅助类别
const dataSubjectAffix = (subjectid:any):AxDataform => ({
  params:{filter_EQS_subject_id: subjectid},
  axApi: axAffixExSubjectList,
  setList:setAffixList
})
//获取辅助核算详情
const dataAffixDetails = (affixid:any,companyId:any):AxDataform => ({
  params:{accountingId: affixid,companyId},
  axApi: axAffixSubjectDetail,
  setList:setAffixDetailList
})
//获取会计科目列表
const dataSubject:AxDataform = {
  axApi: axSubjectAffixList,
  setList: setSubjectList
}
//获取单个辅助核算detail初始余额
const dataInitSubjectBalance = {
  axApi: singleAffixDetailRemainder,
  setTableLoading,
  finishLoading:false
}
//公司列表
const dataCompanyList:AxDataform = {
  axApi: getCompanyList,
  setList: setCompanyList
}

  /* useEffect */
  //监听路由变化
  React.useEffect(() => {
    if (loadFlag && location.pathname===pathname) routerFlash()
    let mystate:any = state
    if (state) {
      searchRef.current?.setFieldsValue({'companyId':mystate.companyId,'ym':[moment(mystate.ym),moment(mystate.ym)],'subjectId':mystate.subjectId})
      searchParams = {'companyId':mystate.companyId,'subjectId':mystate.subjectId,beginYm:mystate.ym,endYm:mystate.ym}
      uniAxList(dataRow())
      uniAxList(dataSubjectAffix(mystate.subjectId))
    }
  }, [location])
  /* mounted */
  React.useEffect(() => {
    // uniAxList(dataRow())
    uniAxList(dataSubject)
    uniAxList(dataCompanyList)
    // resizeFn()
    setLoadFlag(()=>true)
  }, [])

  return (<>
  <TopSearch
    ref={searchRef}
    initialValues={initSearchParams}
    searchList={searchList}
    searchConfirm={searchConfirm}
  />
  <SuperTable
    columns={columns}
    rowList={rowList}
    tableLoading={tableLoading}
    dataRow={dataRow}
  />
  {/* <div className={tablecss.height100}>
    <div id='tablewrap1' style={{width:'calc(100vw - 305px)'}} className={tablecss.detailtable}>
      <Table
        size='small'
        rowKey={'id'}
        columns={columns}
        dataSource={rowList}
        scroll={{y:'calc(100vh - 232px)'}}
        pagination={false}
        loading={tableLoading}
        sticky
      />
    </div>
    <div id='listresize1' className={tablecss.movebar}>5</div>
    <div id='listref1' className={tablecss.subjectlist} style={{width:300}}>
      <div style={{height:36,lineHeight:'36px',paddingLeft:10}}>辅助项目</div>
      <AutoComplete
        value={mainControl.detialAutoValue}
        dropdownMatchSelectWidth={252}
        style={{ width: '100%' }}
        options={affixDetailAutoList}
        onSelect={(value:any,item:any)=>{
          mainControl.detialAutoValue = value
          tempSearchParams.detailId = item.id
          setMainControl(()=>({...mainControl,checkedDetail:item.id}))
        }}
        onSearch={(searchText: string)=>{
          mainControl.detialAutoValue = searchText
          let showarr:any = []
          if (searchText) affixDetailList.forEach((detailitem:any)=>{
            if (detailitem.value.includes(searchText)) showarr.push({value:detailitem.value,id:detailitem.id})
          })
          else showarr = []
          setAffixDetailAutoList(()=>showarr)
        }}
      >
        <Input.Search size="middle" placeholder="搜索" enterButton />
      </AutoComplete>
      <p></p>
      <List
        style={{height:370,overflowY:'auto'}}
        size="small"
        bordered
        dataSource={affixDetailList}
        renderItem={(item:any,index:number) => <List.Item style={{backgroundColor:item.id===mainControl.checkedDetail?'skyblue':''}} onClick={()=>{
          tempSearchParams.detailId = item.id
          setMainControl(()=>({...mainControl,checkedDetail:item.id}))
        }}>{item.no+' '+item.name}</List.Item>}
      />
    </div>
  </div> */}
  </>)
}
