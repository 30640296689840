import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ConfigProvider, DatePicker, FormInstance, Select, Table, TablePaginationConfig } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { getCompanyList, getMaterialList, getMaterialMonthLog } from '../../utils/api'
import { uniAxList, uniAxSearchList, uniCurrentTime, uniDeepArray, uniExportExcel, uniFilterItem, uniFormProcess, uniModifyAxList, uniSelectRows } from '../../utils/uniFucs'
import tablecss from '../../css/custable.module.less'
import { AxDataform, ColumnItem, FormItemObj } from '../../utils/interfaces';
import TopSearch from '../../components/TopSearch';
import SuperTable from '../../components/SuperTable';

export default function MaterialMonthStat() {
const pathname = "/indexpage/materialmonthstat"
const searchRef = createRef<FormInstance>()
// const initSearchParams = {ym: moment().format('YYYY-MM')}
/* hooks */
let location = useLocation()
const navigate = useNavigate()
const formModalRef = createRef<FormInstance>()

const [rowList, setRowList] = useState([])
let [searchParams, setSearchParams] = useState({} as any)
const [tempSearchParams, setTempSearchParams] = useState({ym: uniCurrentTime({day:false})} as any)
const [staffList, setStaffList] = useState([])
const [feeTypeList, setFeeTypeList] = useState([])
const [tableLoading, setTableLoading] = useState(false)
const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 10})
const [materialList, setMaterialList] = useState([])
const [loadFlag, setLoadFlag] = useState(false)
const [companyList,setCompanyList] = useState([])
const [searchYmRange,setSearchYmRange] = useState([] as any)

/* elements */
//搜索行
const searchList:FormItemObj[] = [
{label: '公司', prop:'companyId',type:'select',dataList:companyList,clearable:false,required:true,change:(value:any)=>{
  searchRef.current?.setFieldsValue({'ym':null})
  let finditem:any = companyList.find((item:any)=>item.id===value)
  if (finditem.initialize===1) setSearchYmRange([finditem.startYm,finditem.endYm])
  else setSearchYmRange([])
  uniAxList(dataMaterial(value))
  searchRef.current?.setFieldsValue({materielId:null})
}},
{label: '月份', prop:'ym',type:'datePicker',required:true,clearable:false,datepickerProps:{returnType:'string',type:'month',disabledDate:(current)=>{
  if (searchYmRange.length<2) return true
  else return current<moment(searchYmRange[0]).startOf('month') || current>moment(searchYmRange[1]).endOf('month')
}}},
{label: '物料', prop:'materielId',type:'select',dataList:materialList},
]
//列项
const columns:ColumnItem[] = [
{title: '排序号',key: 'count',},
{title: '月份',key: 'ym',},
{title: '物料名称',key: 'materielName'},
{title: '所属公司',key: 'companyName'},
{title: '当前库存',key: 'stock'},
{title: '物料单位',key: 'unit'},
{title: '采购单价',key: 'buyunitPrice',type:'price'},
// {title: '期初数量',key: 'initNum'},
// {title: '期初金额',key: 'initMoney',type:'price'},
// {title: '期初余额',
//   children: [
//     { title: '数量', dataIndex: 'initNum', key: 'initNum' },
//     { title: '金额', dataIndex: 'initMoney', key: 'initMoney', render: (value:any) => (value / 100).toFixed(2) }
//   ]
// },
{title: '采购量',key: 'inNum'},
{title: '采购额',key: 'buyPrice',type:'price'},
{title: '申领单价',key: 'salePrice',type:'price'},
{title: '申领量',key: 'outNum'},
{title: '申领额',key: 'applyPrice',type:'price'},
// {title: '期末数量', key: 'endNum' },
// {title: '期末金额', key: 'endMoney',type:'price'},
]
//导出表格的列项
const exportColumns:any = [
{title: '排序号',dataIndex: 'count'},
{title: '月份',dataIndex: 'ym',},
{title: '物料名称',dataIndex: 'materielName',},
{title: '所属公司',dataIndex: 'companyName',},
{title: '物料单位',dataIndex: 'unit'},
{title: '采购单价',dataIndex: 'buyunitPrice'},
{title: '销售单价',dataIndex: 'salePrice',},
{ title: '期初数量', dataIndex: 'initNum', key: 'initNum' },
{ title: '期初金额', dataIndex: 'initMoney', key: 'initMoney' },
{title: '本期采购量',dataIndex: 'inNum'},
{title: '本期采购额',dataIndex: 'buyPrice'},
{title: '本期申领量',dataIndex: 'outNum'},
{title: '本期申领额',dataIndex: 'applyPrice'},
{ title: '期末数量', dataIndex: 'endNum' },
{ title: '期末金额', dataIndex: 'endMoney' },
{title: '当前库存',dataIndex: 'stock'}
]
//导出当前页，需要的数据修饰
const exportModify:any = {
divideKey:[
  {newKey: 'buyPrice', key:'buyPrice', value: 100},
  {newKey: 'salePrice', key:'salePrice', value: 100},
  {newKey: 'initMoney', key:'initMoney', value: 100},
  {newKey: 'endMoney', key:'endMoney', value: 100},
],
notNullColumns: columns,
}

/* funcs */
const searchConfirm = async (formValus:any) => {setPagination({...pagination,current:1});
  searchParams = await uniFormProcess(formValus,searchList)
  setSearchParams(searchParams)
  uniAxList(dataRow())
}
//接口获取数据修饰
const modifyRowList = ({initNum, dayLogMonthData}:any) => {
  dayLogMonthData.forEach((item:any, index:number) => {
    item.count = index + 1
    item.key = item.ym + item.materielId
    let initNumItem:any = uniFilterItem(initNum, 'materielId', item.materielId)
    // item.initNum = initNumItem.initNum
    // item.initMoney = initNumItem.initNum * initNumItem.buyPrice
    // item.endNum = item.initNum + item.inNum - item.outNum
    // item.endMoney = item.endNum * initNumItem.buyPrice
    item.buyunitPrice = item.buyPrice ? item.buyPrice / item.inNum : 0
    item.salePrice = item.applyPrice ? item.applyPrice / item.outNum : 0
    item.stock = initNumItem.stock
    item.unit = initNumItem.unit
    // Object.assign(item, initNumItem)
  })
  return dayLogMonthData
}
//路由刷新请求
const routerFlash = () => {
  // uniAxList(dataRow())
  uniAxList(dataCompanyList)
}

/* 接口参数 */
const dataRow = ():AxDataform => ({
  params: searchParams,
  axApi: getMaterialMonthLog,
  modifyFunc: modifyRowList,
  setList: setRowList,
  setTableLoading
})
//物料列表
const dataMaterial = (companyId:any):AxDataform=>({
  params: {filter_EQS_company_id: companyId},
  axApi: getMaterialList,
  setList: setMaterialList,
})
const dataCompanyList:any = {
  axApi: getCompanyList,
  setList: setCompanyList,
}
/* useEffect */
//监听路由变化
React.useEffect(() => {
  if (loadFlag && location.pathname===pathname) routerFlash()
}, [location])
/* mounted */
React.useEffect(() => {
  // uniAxList(dataRow())
  uniAxList(dataCompanyList)
  setLoadFlag(()=>true)
  // uniAxList(uniCompanyData)
}, [])

return (
  <>
    <TopSearch
      ref={searchRef}
      initialValues={{}}
      searchList={searchList}
      searchConfirm={searchConfirm}
    />
    <SuperTable
      columns={columns}
      rowList={rowList}
      tableLoading={tableLoading}
      dataRow={dataRow}
    />
    {/* <p>
    <Select
      allowClear
      style={{ width: 200 }}
      placeholder="物料名称"
      onChange={(value) => setTempSearchParams(() => ({...tempSearchParams, materielId:value || null}))}
    > 
      {materialList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
    </Select>&nbsp;
    <ConfigProvider locale={locale}>
      <DatePicker disabledDate={(current)=>{return current && current > moment().endOf('day')}} picker="month" defaultValue={moment(uniCurrentTime({day:false}), 'YYYY-MM')} onChange={(date, dateString)=>setTempSearchParams(() => ({...tempSearchParams, ym: dateString || null}))}/>
    </ConfigProvider>&nbsp;
    <Button onClick={() => uniAxSearchList({setSearchParams, dataRow(), params: tempSearchParams})} type='primary'>查询</Button>&nbsp;
    <Button type="primary" onClick={() => uniExportExcel({columns:exportColumns, rowList: uniDeepArray(rowList), modifyFunc:uniModifyAxList, modifyData:exportModify,title:'月度物料统计表', fileName: '月度物料统计表'+uniCurrentTime({})})}>导出</Button> &nbsp;
    </p> */}
  </>)
}
