import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ConfigProvider, DatePicker, Form, FormInstance, Input, InputNumber, message, Modal, Pagination, Popconfirm, Radio, Select, Table, TablePaginationConfig, Tooltip, Typography } from 'antd'
import { confirmMaterialApply, confirmMaterialPay, createMaterialApply, deleteMaterialApply, getCompanyAccoutList, getCompanyList, getCustomerList, getMaterialApplyList, getMaterialList, getMaterialPriceList, getStaffCharList, getStaffList, updateMaterialApply } from '../../utils/api'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { uniAddRowSubmit, uniAxList, uniAxSearchList, uniBatchConfirm, uniChgRowSubmit, uniConfirm, uniCurrentTime, uniDatePikcerChange, uniDeepArray, UniDeleteAll, uniDeleteRow, uniExportExcel, uniFilterItem, uniFormProcess, uniInitFormdata, uniModifyAxList, uniModifyRow, uniOnBatch, uniOpAlarm, uniQuitModal, uniSelectRows, uniTableChange, uniToProof, uniValueToName } from '../../utils/uniFucs'
import FormModal from '../../components/FormModal'
import { applyStatusList, collectionStatus, csDateRages, userTypeList } from '../../utils/constant'
import { NameValue } from '../../utils/beans'
import TextArea from 'antd/lib/input/TextArea';
import formModal from '../../css/formModal.module.less'
import tablecss from '../../css/custable.module.less'
import TopSearch from '../../components/TopSearch';
import { AxDataform, ColumnAction, ColumnItem, FormItemObj } from '../../utils/interfaces';
import SuperTable from '../../components/SuperTable';
import FormDialog from '../../components/FormDialog';
import { getUserInfoKey } from '../../utils/localstorage';

export default function MaterialClaim() {
const pathname = "/indexpage/materialclaim"
const searchRef = createRef<FormInstance>()
const initSearchParams = {page:1,orderBy:"apply_time", orderDir: "desc"}
const initFormdata = {}
const modalRef = createRef<FormInstance>()
const initsumProps:any = []
const defaultauth = getUserInfoKey('positionKey')!=='STAFF'
/* hooks */
let location = useLocation()
const navigate = useNavigate()
const formModalRef = createRef<FormInstance>()
const [addModal, setAddModal] = useState(false)
const [chgModal, setChgModal] = useState(false)
const [logModal, setLogModal] = useState(false)
const [confirmModal, setConfirmModal] = useState(false)
const [cashModal, setCashModal] = useState(false)
const [stockModal, setStockModal] = useState(false)
const [staticModal, setStaticModal] = useState(false)
const [cancelModal, setCancelModal] = useState(false)
const [companyList, setCompanyList] = useState([])
const [logList, setLogList] = useState([])
const [rowList, setRowList] = useState([])
const [backRowList, setBackRowList] = useState([])
const [modalItemVisible, setModalItemVisible] = useState({stock:0} as any)
const [showCashAccount, setShowCashAccount] = useState(true)
const [selectedRowKeys, setSelectedRowKeys] = useState([])
const [selectedRows, setSelectedRows] = useState([] as any)
const [currentRow, setCurrentRow] = useState({} as any)
let [searchParams, setSearchParams] = useState({...initSearchParams} as any)
let [tableParams, setTableParams] = useState({...initSearchParams} as any)
const [staffList, setStaffList] = useState([])
const [feeTypeList, setFeeTypeList] = useState([])
const [tableLoading, setTableLoading] = useState(false)
const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
const [accountList, setAccountList] = useState([])
const [materialList, setMaterialList] = useState([])
const [modalMaterialList, setModalMaterialList] = useState([])
const [customModalInitial, setCustomModalInitial] = useState({} as any)
const [loadFlag, setLoadFlag] = useState(false)
const [modalLoading, setModalLoading] = useState(false)
const [exportLoading, setExportLoading] = useState(false)
const [materialPriceList, setMaterialPriceList] = useState([])
const [staffCharList, setStaffCharList] = useState([] as any)
const [userList,setUserList] = useState([])
const [areaList,setAreaList] = useState([])
const [getUserList, setGetUserList] = useState([])
const [batchRows,setBatchRows] = useState([])
const [showModal, setShowModal] = useState(false)
const [modalFlag, setModalFlag] = useState('new')
let [formInitials, setFormInitials] = useState({...initFormdata})
let [selectMaterial,setSelectMaterial] = useState({} as any)
let [modalEndym, setModalEndym] = useState(moment().format('YYYY-MM'))
const [searchYmRange,setSearchYmRange] = useState([] as any)
const [modalcompanyItem,setModalcompanyItem] = useState({} as any)

/* elements */
//搜索行
const searchList:FormItemObj[] = [
{label: '公司', prop:'filter_EQS_company_id',type:'select',dataList:companyList,clearable:false,required:true,change:(value:any)=>{
  searchRef.current?.setFieldsValue({'filter_BETWEENS_provide_time':null})
  let finditem:any = companyList.find((item:any)=>item.id===value)
  if (finditem.initialize===1) setSearchYmRange([finditem.startYm,finditem.endYm])
  else setSearchYmRange([])
}},
{label: '发放日期', prop:'filter_BETWEENS_provide_time',type:'dateRange',daterangeProps:{returnType:'string',disabledDate:(current)=>{
  if (searchYmRange.length<2) return true
  else return current<moment(searchYmRange[0]).startOf('month') || current>moment(searchYmRange[1]).endOf('month')
}}},
{label: '发放人', prop:'filter_LIKES_provide_user_name'},
{label: '备注', prop:'filter_LIKES_remark'},
{label: '物料', prop:'filter_EQS_materiel_id',type:'select',dataList:materialList},
{label: '发放状态', prop:'filter_EQL_apply_status',type:'select',dataList:applyStatusList},
// {label: '收款账户', prop:'filter_EQL_account_id',type:'select',dataList:accountList},
// {label: '收款日期', prop:'filter_BETWEENS_collection_time',type:'dateRange',daterangeProps:{returnType:'string'}},
]
//列项
const columns:ColumnItem[] = [
{title: '申领人',key: 'userName'},
{title: '申领数量',key: 'applyNum'},
{title: '申领日期',key: 'applyTime',custval: ({row,value}:any) => value ? value.split(' ')[0] : ''},
{title: '人员性质',key: 'userType', custval:({row,value}:any) => uniValueToName({list:staffCharList,value,propId:'type'})},
{title: '物料名称',key: 'materielName'},
{title: '所属公司',key: 'companyName'},
{title: '成本价',key: 'buyPrice',custval:({row,value}:any) => value ? (value/100).toFixed(2) : 0},
// {title: '销售单价',key: 'salePrice',custval:({row,value}:any) => value ? (value/100).toFixed(2) : 0},
// {title: '销售金额',key: 'saleTotalPrice',custval:({row,value}:any) => value ? (value/100).toFixed(2) : 0},
{title: '凭证号',key: 'accountProof',width:80,render: (value:any,record:any) => {
  return value.map((item:any) => <span key={item.accountProofNo}><Typography.Link onClick={()=>uniToProof(record,item.accountProofId,navigate)}>{item.accountProofNo}</Typography.Link> </span>)
},custval:({row,value}:any)=>value.map((item:any)=>item.accountProofNo).join(',')},
{title: '发放状态',key: 'applyStatus',type:'tag',custval:({row,value}:any) => uniValueToName({list:applyStatusList,value})},
{title: '发放人',key: 'provideUserName'},
{title: '发放日期',key: 'provideTime',custval: ({row,value}:any) => value ? value.split(' ')[0] : ''},
// {title: '收款日期',key: 'collectionTime',custval: ({row,value}:any) => value ? value.split(' ')[0] : ''},
{title: '备注',key: 'remark'},
{title: '创建时间',key: 'createDate'},
{title: '操作',key:'operation',type: 'operation',width:100,hide:!defaultauth,opProps:{opList:[
  {name:'修改',disabled:({row}:any)=>row.applyStatus===1,action:({row}:any) => {
    let finditem:any = companyList.find((item:any)=>item.id===row.companyId)
    // setModalEndym(finditem.endYm)
    setModalcompanyItem(finditem)
    setModalFlag('update')
    setCurrentRow(row)
    setFormInitials(uniInitFormdata(row,formItems))
    setShowModal(true)
    let charitem = staffCharList.find((item:any)=>item.type===row.userType)
    setGetUserList(()=>staffList.filter((item:any)=>item.userCharacterId == charitem.id))
    setModalMaterialList(materialList.filter((item:any)=>item.companyId===row.companyId))
    setSelectMaterial(materialList.find((item:any)=>item.id==row.materielId))
  }},
  {name:'删除',disabled:({row}:any)=>row.applyStatus===1,action:({row}:any) =>uniOpAlarm(()=>uniAxList(dataDelrow(row.id)))}
]}}
]

//底部操作
const bottomActions:ColumnAction[] = [
{label: '新增', type:'primary', action: () => {setModalFlag('new');setShowModal(() => true)}},
{label: '批量发放', type:'primary',hide:!defaultauth,disabled:!selectedRowKeys.length,action: () => {setModalFlag('confirm');setShowModal(true)}},
{label: '导出',type:'primary',loading:exportLoading,disabled: rowList.length<=0,action: () =>uniExportExcel({
  exportLoading,columns:columns.slice(0,-1),title:'物料申领明细',fileName:'物料申领明细',
  showSummary:false,sumProps:initsumProps,axApi:getMaterialApplyList,tableParams,setExportLoading
})},
]

//添加和修改中的model项
const formItems:FormItemObj[] = [
{label: '人员性质',prop: 'userType',type:'select',show:defaultauth,dataList:staffCharList,selectProps:{propId:'type'},change:(value:any,option:any)=>{
  let finditem = staffCharList.find((item:any)=>item.type===value)
  modalRef.current?.setFieldsValue({userId: null})
  if (finditem) setGetUserList(()=>staffList.filter((item:any)=>item.userCharacterId == finditem.id))
}},
{label: '选择公司',prop: 'companyId',type:'select',dataList:companyList,clearable:false,selectProps:{requireName:'companyName'},change: async (value:any)=>{
  let finditem:any = companyList.find((item:any)=>item.id===value)
  if (finditem.initialize===0) {
    modalRef.current?.setFieldsValue({companyId:null})
    return message.error('该公司未初始化, 不能新建申领')
  }
  // setModalEndym(finditem.endYm)
  setModalcompanyItem(finditem)
  let resdata = await uniAxList(dataStaffList(value))
  let usertype = modalRef.current?.getFieldValue('userType')
  if (usertype) {
    let finditem = staffCharList.find((item:any)=>item.type===usertype)
    setGetUserList(()=>resdata.filter((item:any)=>item.userCharacterId == finditem.id))
  } else setGetUserList(()=>resdata)
  modalRef.current?.setFieldsValue({materielId:null,buyPrice:null, applyNum:null,totalPrice:null})
  setModalMaterialList(materialList.filter((item:any)=>item.companyId===value))
}},
{label: '物料名称',prop: 'materielId',type:'select',dataList:modalMaterialList,selectProps:{requireName:'materielName'},change:(value:any)=>{
  // let userId = modalRef.current?.getFieldValue("userId")
  const materialItem:any = uniFilterItem(materialList, 'id', value)
  setSelectMaterial(materialItem)
  modalRef.current?.setFieldsValue({buyPrice:materialItem.buyPrice/100, applyNum:null,totalPrice:null})
  // if (userId) {
    // let priceItem:any = uniFilterItem(materialPriceList,'id',1,[{key:'materielId',value},{key:'userId',value:userId}])
    //buyPrice:materialItem.buyPrice/100, salePrice:priceItem.salePrice/100,
    // if(priceItem) modalRef.current?.setFieldsValue({salePrice:priceItem.salePrice/100,buyPrice:null, applyNum:null,totalPrice:null})
  //   modalRef.current?.setFieldsValue({buyPrice:null, applyNum:null,totalPrice:null})
  // } else modalRef.current?.setFieldsValue({salePrice:materialItem.salePrice/100,buyPrice:null, applyNum:null,totalPrice:null})
}},
{label: '申领人',prop: 'userId',type:'select',show:defaultauth,dataList:getUserList,selectProps:{requireName:'userName'},change:(value:any)=>{
  // let materielId = modalRef.current?.getFieldValue("materielId")
  // let applyNum = modalRef.current?.getFieldValue("applyNum")
  // if (materielId) {
  //   let priceItem:any = uniFilterItem(materialPriceList,'id',1,[{key:'materielId',value:materielId},{key:'userId',value:value}])
  //   if (priceItem) modalRef.current?.setFieldsValue({salePrice:priceItem.salePrice / 100, saleTotalPrice:applyNum?(priceItem.salePrice / 100)*applyNum:null})
  //   else {
  //     let materialItem:any = uniFilterItem(materialList, 'id', materielId)
  //     modalRef.current?.setFieldsValue({salePrice:materialItem.salePrice / 100,saleTotalPrice:applyNum?(materialItem.salePrice / 100)*applyNum:null})
  //   }
  // }
}},
{label: '成本价',prop: 'buyPrice',type:'price',readonly:true,numProps:{precision:2},change:(value:any)=>{
  let num = modalRef.current?.getFieldValue('applyNum')
  modalRef.current?.setFieldsValue({totalPrice:num ? value * num : 0})
}},
// {label: '销售单价',prop: 'salePrice',type:'price',readonly:true,numProps:{precision:2}},
{label: '申领数量',prop: 'applyNum',type:'number',suffix: <span>{selectMaterial.id?('/'+selectMaterial.stock):''}</span>,change:(value:any)=>{
  let price = modalRef.current?.getFieldValue('buyPrice')
  modalRef.current?.setFieldsValue({totalPrice:price ? value * price : 0})
}},
{label: '总价',prop: 'totalPrice',type:'price',readonly:true,numProps:{precision:2},change:(value:any)=>{
  let num = modalRef.current?.getFieldValue('applyNum')
  modalRef.current?.setFieldsValue({buyPrice:num ? value / num : 0})
}},
// {label: '销售金额',prop: 'saleTotalPrice',type:'price',numProps:{precision:2}},
{label: '申领日期',prop: 'applyTime',type:'datePicker',datepickerProps:{returnType:'string',disabledDate:(current)=>current<moment(modalcompanyItem.beginYm).startOf('month') || current>moment(modalcompanyItem.endYm).endOf('month')}},
{label: '备注/业务员',prop: 'remark',required:false}
]

//确认发放modal中的item
const confirmItems:FormItemObj[] = [
{label: '发放时间',prop: 'provideTime',type:'datePicker',datepickerProps:{returnType:'string'}},
]
//确认收款modal中的item
const cashItems = [
  {
    label: '收款账户',
    name: 'accountId',
    rules: [{ required: true, message: '收款账户不能为空' }],
    element: 
      <Select
        showSearch
        optionFilterProp='children'
        allowClear
        style={{ width: '100%' }}
        placeholder="请选择付款账户"
      >
        { accountList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>) }
      </Select>
            
  },
  {
    label: '收款时间',
    name: 'collectionTime',
    rules: [{ required: true, message: '收款时间不能为空' }],
    element:<ConfigProvider locale={locale}>
              <DatePicker disabledDate={(current)=>{return current && current > moment().endOf('day')}} style={{width:'100%'}} onChange={(date, dateString)=>uniDatePikcerChange({formRef:formModalRef,dateString:dateString,keyWord:'collectionTime'})}/>
            </ConfigProvider>      
  },
]

/* funcs */
//路由刷新请求
const routerFlash = () => {
  // uniAxList(dataRow())
  // uniAxList(uniStaffData)
  // uniAxList(uniCustomerData)
  uniAxList(uniMaterialData)
  uniAxList(uniCaData)
  uniAxList(uniCompanyData)
  uniAxList(uniMaterialPriceData)
}
const searchConfirm = async (formValus:any) => {
  pagination.current = 1
  setPagination({...pagination,current:1});
  searchParams = {...initSearchParams, ...await uniFormProcess(formValus,searchList)}
  setSearchParams(searchParams)
  setTableParams({...searchParams})
  uniAxList(dataRow())
}
//点击新增
const onAdd = () => {
  modalItemVisible.userType=1;
  modalItemVisible.stock=0;
  setModalMaterialList(()=>[]);
  setAddModal(() => true)
}

/* 接口参数 */
const dataRow = (current?:number):AxDataform => ({
  params: {...searchParams, current: current || pagination.current, size:pagination.pageSize},
  axApi: getMaterialApplyList,
  setList: setRowList,
  setTableLoading,
  setPagination
})
//新增物料申领 getUserInfoKey('positionKey')!=='STAFF'
const dataNewrow = (params:any):AxDataform =>{
  let tempparam = {...params,groupItemId:params.companyId}
  if (!defaultauth) {
    let usercharId = getUserInfoKey('userCharacterId')
    let findcharItem = staffCharList.find((item:any)=>item.id === usercharId)
    tempparam = {...tempparam,userId:getUserInfoKey('id'),userName:getUserInfoKey('name'),userType:findcharItem?.type}
  }
  return {
    params: tempparam,
    axApi: createMaterialApply,
    setModalLoading,
    setShowModal,
    awaitFunc: ()=>uniAxList(dataRow())
  }
}
//修改物料申领
const dataUpdaterow = (params:any):AxDataform => ({
  params: {...params, id:currentRow.id},
  axApi: updateMaterialApply,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//删除物料申领
const dataDelrow = (rowid:any):AxDataform => ({
  params: {id:rowid},
  axApi: deleteMaterialApply,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//批量发放
const dataConfirmRows = (params:any):AxDataform => {
  let temparr = selectedRowKeys.map((itemkey:any)=>({...params,id:itemkey}))
  return {
    params: temparr,
    axApi: confirmMaterialApply,
    setModalLoading,
    setShowModal,
    awaitFunc: (predata:any) => uniAxList(dataRow())
  }
}
//人员列表
const dataStaffList = (companyId:any):AxDataform => ({
  params: {size:999,groupItemId:companyId},
  axApi: getStaffList,
  setList: setStaffList,
})
// const uniCustomerData:any = {
//   params: {size:9999},
//   axApi: getCustomerList,
//   setList: setCustList,
// }
const uniMaterialData:any = {
  axApi: getMaterialList,
  setList: setMaterialList,
}
//人员性质
const dataUserChars:any = {
  axApi: getStaffCharList,
  setList: setStaffCharList
}
//账户
const uniCaData:any = {
  params:{filter_NINL_account_type:'4'},
  axApi: getCompanyAccoutList,
  setList: setAccountList
}
const uniCompanyData:any = {
  axApi: getCompanyList,
  setList: setCompanyList,
}
const uniMaterialPriceData:any = {
  axApi: getMaterialPriceList,
  setList: setMaterialPriceList,
}
/* useEffect */
//监听路由变化
React.useEffect(() => {
  if (loadFlag && location.pathname===pathname) routerFlash()
}, [location])
/* mounted */
React.useEffect(() => {
  // uniAxList(dataRow())
  // uniAxList(uniStaffData)
  // uniAxList(uniCustomerData)
  uniAxList(uniMaterialData)
  uniAxList(uniCaData)
  uniAxList(uniCompanyData)
  // uniAxList(uniMaterialPriceData)
  uniAxList(dataUserChars)
  setLoadFlag(()=>true)
}, [])

return (
  <>
    <TopSearch
      ref={searchRef}
      searchList={searchList}
      searchConfirm={searchConfirm}
    />
    <SuperTable
      columns={columns}
      rowList={rowList}
      bottomActions={bottomActions}
      tableLoading={tableLoading}
      pagination={pagination}
      dataRow={dataRow}
      rowSelection={{setSelectedRowKeys, setSelectedRows}}
    />
    <FormDialog
      ref={modalRef}
      title={modalFlag==='new'?'新增申领':modalFlag==='update'?'修改申领':'确认发放'}
      show={showModal}
      formItems={modalFlag==='confirm'?confirmItems:formItems}
      modalLoading={modalLoading}
      formInitials={formInitials}
      cancel={()=>{
        setModalcompanyItem({})
        setShowModal(()=>false)
        setCurrentRow({})
        setSelectMaterial({})
        setFormInitials({...initFormdata})
      }}
      confirm={ async (formValues:any)=>(
        modalFlag==='new'?uniAxList(dataNewrow(await uniFormProcess(formValues,formItems))):
        modalFlag==='update'?uniAxList(dataUpdaterow(await uniFormProcess(formValues,formItems))):
        modalFlag==='confirm'?uniAxList(dataConfirmRows(await uniFormProcess(formValues,confirmItems))):null
      )}
    ></FormDialog>
  </>
)
}
